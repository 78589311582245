@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --yellow-color: #ffbc07;
  --yellow-color--dark-10: #e6a906;
  --yellow-color--dark-20: #cc9606;
  --yellow-color--dark-30: #b38405;
  --dark-blue-color: #0c0e26;
  --blue-color: #161834;
  --text-color: #fdfdfd;
  --text-color-dark: #b3b3b3;
  --error-color: #d7676c;
  --green-color: #3ada7d;

  /* Screen width */
  --sw-xs: 320px;
  --sw-sm: 480px;
  --sw-md: 768px;
  --sw-lg: 1024px;
  --sw-xl: 1200px;

  /* Border radius */
  --br-lg: 15px;
  --br-xxl: 3rem;

  /* Font weight */
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: var(--dark-blue-color);
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
  color: var(--text-color);
}

.noscroll {
  overflow: hidden;
}

.container {
  padding: 1.6rem 2rem;
  margin: 0 auto;
  max-width: var(--sw-xl);
}

.section--blue-bg {
  background-color: var(--blue-color);
}

/* Scroolbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0f122c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #202140;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(75, 75, 75);
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  --webkit-appearance: button;
}

/**
  * Remove the gray background on active links in IE 10.
  */

a {
  text-decoration: none;
  background-color: transparent;
  color: inherit;
}

textarea {
  resize: none;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-color);
  transition: background-color 5000s ease-in-out 0s;
}
