.feature__container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.feature__heading {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.feature__heading > svg {
  width: 4rem;
  height: 4rem;
  fill: var(--yellow-color);
}

.feature__title {
  font-weight: var(--fw-semi-bold);
  font-size: 2rem;
}

.feature__description {
  font-weight: var(--fw-medium);
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: var(--text-color-dark);
}

@media (min-width: 768px) {
  .feature__heading {
    flex-direction: row;
    gap: 1.6rem;
    align-items: center;
  }

  .feature__title {
    font-size: 2.4rem;
  }
}
