.features__container {
  padding-block: 4rem;
}

.features__heading {
  font-weight: var(--fw-semi-bold);
  font-size: 3rem;
  margin-bottom: 4rem;
  text-align: center;
}

.features__items {
  display: grid;
  gap: 4rem;
}

@media (min-width: 768px) {
  .features__heading {
    font-size: 4rem;
  }

  .features__items {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .features__container {
    padding: 4rem;
    border-radius: var(--br-xxl);
  }

  .features__items {
    gap: 8rem;
  }
}
