.partners__container {
  padding-block: 3rem;
  width: 100%;
}

.partners__heading {
  font-weight: var(--fw-semi-bold);
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--text-color-dark);
}

.logos_slider {
  display: flex;
  overflow: hidden;
}

.logos_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  animation: logoLoop 30s linear infinite;
}

.logo_item {
  display: grid;
  place-items: center;
}

.logo_item img {
  height: 4rem;
  margin-inline: 5rem;
}

@keyframes logoLoop {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
