.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.4rem;
  color: var(--text-color-dark);
  font-size: 1.4rem;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  align-items: center;
}

.footer__socials {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.footer__social-item {
  display: flex;
}

.footer__social-item svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: var(--text-color-dark);
}

.footer__platform-btn {
  background-color: var(--yellow-color);
  color: var(--dark-blue-color);
  border: none;
  padding: 1.2rem 1.8rem;
  border-radius: var(--br-xxl);
  font-weight: var(--fw-semi-bold);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all ease-in 0.2s;
}

.footer__platform-btn:hover {
  background-color: var(--yellow-color--dark-10);
}

.footer__platform-btn:active {
  background-color: var(--yellow-color--dark-20);
}

.footer__platform-btn > svg {
  width: 1.6rem;
  height: 1.6rem;
  fill: var(--dark-blue-color);
}
