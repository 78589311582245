.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  inset: 0;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
}

.modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--blue-color);
  border-radius: var(--br-lg);
  padding: 2.4rem 6.5rem;
  max-width: 90%;
}

.modal__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.modal__header svg {
  height: 5.2rem;
  fill: var(--green-color);
}

.modal__header h2 {
  font-size: 2.4rem;
  font-weight: var(--fw-semi-bold);
}

.modal__body {
  padding: 1rem 0 2.4rem;
  text-align: center;
  color: var(--text-color-dark);
}

.modal__close-btn {
  border: none;
  padding: 1.2rem 1.8rem;
  border-radius: var(--br-xxl);
  font-weight: var(--fw-semi-bold);
  cursor: pointer;
  background-color: var(--green-color);
  color: var(--dark-blue-color);
}
