.hero__container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.hero__info {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.hero__heading {
  font-weight: var(--fw-bold);
  font-size: 4rem;
  line-height: 5.2rem;
  color: var(--text-color);
}

.hero__sub-heading {
  font-weight: var(--fw-medium);
  font-size: 1.8rem;
  color: var(--text-color-dark);
}

.hero__image-wrapper {
  text-align: center;
}

.hero__image {
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 480px) {
  .hero__container {
    margin-top: 4.2rem;
    gap: 2rem;
  }
}

@media (min-width: 768px) {
  .hero__container {
    flex-direction: row;
    align-items: center;
  }

  .hero__info {
    flex: 1;
    gap: 3rem;
  }

  .hero__sub-heading {
    font-size: 2rem;
  }

  .hero__image-wrapper {
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .hero__heading {
    font-size: 4.8rem;
    line-height: 6.8rem;
  }
}
