.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header__logo_image {
  height: 3.5rem;
}

.header__logo_text {
  font-size: 2.4rem;
  color: var(--yellow-color);
  font-weight: var(--fw-semi-bold);
  text-transform: lowercase;
  display: none;
}

.header__cta {
  border: 1px solid var(--yellow-color);
  border-radius: var(--br-xxl);
  font-size: 1.4rem;
  font-weight: var(--fw-medium);
  padding: 0.8rem 1.6rem;
  transition: all ease-in 0.2s;
  cursor: pointer;
}

.header__cta:hover {
  background-color: var(--yellow-color);
  color: var(--dark-blue-color);
}

@media (min-width: 480px) {
  .header__logo_text {
    display: block;
  }
}

@media (min-width: 768px) {
  .header__logo_image {
    height: 4rem;
  }

  .header__logo_text {
    font-size: 3rem;
  }

  .header__cta {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }
}

@media (min-width: 1200px) {
  .header__container {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
}
