.contact-us__container {
  margin-block: 4rem;
  padding-block: 4rem;
  width: 100%;
  max-width: 48rem;
}

.contact-us__heading {
  font-weight: var(--fw-semi-bold);
  font-size: 3rem;
  margin-bottom: 4rem;
  text-align: center;
}

@media (min-width: 480px) {
  .contact-us__container {
    border-radius: var(--br-xxl);
  }
}

@media (min-width: 768px) {
  .contact-us__heading {
    font-size: 4rem;
  }
}

@media (min-width: 1200px) {
  .contact-us__container {
    padding: 4rem;
  }

  .contact-us__container {
    max-width: 55rem;
  }
}
