.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}

.form-field-group {
  width: 100%;
}

.form-field {
  border: 1px solid var(--text-color-dark);
  border-radius: var(--br-lg);
  background-color: transparent;
  color: var(--text-color);
  font-size: 1.4rem;
  padding: 1.6rem 2.4rem;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

.form-field__error {
  margin-top: 0.8rem;
  color: var(--error-color);
  font-size: 1.4rem;
}

.submit-btn {
  background-color: var(--yellow-color);
  color: var(--dark-blue-color);
  border: none;
  padding: 1.2rem 1.8rem;
  border-radius: var(--br-xxl);
  font-weight: var(--fw-semi-bold);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all ease-in 0.2s;
}

.submit-btn:hover {
  background-color: var(--yellow-color--dark-10);
}

.submit-btn:active {
  background-color: var(--yellow-color--dark-20);
}

.submit-btn > svg {
  width: 1.2rem;
  height: 1rem;
  fill: var(--dark-blue-color);
}

.submit-btn:disabled {
  background-color: var(--yellow-color--dark-30);
  cursor: not-allowed;
}
